.LoginForm {
    .validateMessage {
        background-color: var(--main-color-error);
        padding: 1rem;
        color: #fff;
        border-radius: .2rem;
        margin-bottom: 1.5rem;
        text-align: center;
    }

    .form__row--button {
        margin-top: 2rem;
    }
}