.CreditCard {
    h2 {
        color: var(--main-color-primary);
        font-size: 2rem;
    }

    &__flags {
        width: 100%;
        height: 40px;
        background: url('../CreditCard/flags.webp') no-repeat;
        background-size: contain;
        margin-bottom: 2rem;
    }

    &__columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1.5rem;
    }

    &__half-columns {
        width: calc(50% - 1rem);
    }
}