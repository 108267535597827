
.CreatedView {
    h3 {
        font-size: 3rem;
        color: #fff;
        max-width: 500px;
    }

    &__line {
        margin-bottom: 2rem;
    }

    &__label {
        text-transform: uppercase;
        font-size: .7rem;
        font-weight: 700;
        letter-spacing: .1rem;
        color: rgba(255,255,255,.4);
        margin-bottom: .4rem;
    }

    &__link {
        a {
            font-size: 2rem;
            font-weight: 800;
            color: #fff;
            display: block;
        }
    }

    &__buttons {
        margin-top: 3rem;

        .btn {
            display: inline-block;
            background-color: #fff;
            padding: .5rem 1rem;
            margin-right: 1rem;
            font-weight: 700;
            border:none;

            &:hover {
                color: #fff;
                text-decoration: none;
            }

            &--copy {
                color: var(--main-color-secondary);

                &:hover {
                    background-color: var(--main-color-secondary);
                }
            }

            &--qrcode {
                color: #000;

                &:hover {
                    background-color: #000;
                }
            }

            &--facebook {
                color: #3b5998;

                &:hover {
                    background-color: #3b5998;
                }
            }

            &--twitter {
                color: #1DA1F2;

                &:hover {
                    background-color: #1DA1F2;
                }
            }
        }
    }

    &__columns {
        margin-top: 5rem;
        display: flex;
    }

    &__column {
        width: 50%;
        padding-right: 2.5rem;
    }

    &__value {
        color: #fff;
        font-size: 2rem;
        font-weight: 800;
        margin: 0;
    }

    &__text {
        font-size: 1.1rem;
        color: #fff;
        line-height: 2rem;
        margin: 0;
    }

    &__afterPayment {
        margin: 0;
        font-size: 1.1rem;
        color: #fff;
        line-height: 2rem;

        p {
            margin: 0;

            em {
                font-size: .9rem;
            }
        }

        a {
            color: #fff;
        }
    }

    &__back {
        a {
            color: #fff;
        }
    }
}