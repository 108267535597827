.Boleto {
    h2 {
        color: var(--main-color-primary);
        font-size: 2rem;
    }

    &__columns,
    &__buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1.5rem;
        margin-bottom: 2rem;
    }

    &__label {
        color: #C2C8EB;
        text-transform: uppercase;
        font-size: .8rem;
        letter-spacing: .1rem;
    }

    &__text {
        font-size: 1.4rem;
        margin: 0;
        padding: 0;
    }

    &__number {
        background-color: #EEF0F8;
        padding: 2rem;
        text-align: center;
        border-radius: .2rem;
        font-size: 1.4rem;
        line-height: 2.2rem;
    }

    &__buttons {
        margin-top: 5rem;
    }
}