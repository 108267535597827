.UserInfo {
    position: fixed;
    bottom: 1.5rem;
    left: 2.5rem;
    display: flex;
    align-items: center;

    img {
        width: 3rem;
        height: 3rem;
        border-radius: 3rem;
        box-shadow: 0 8px 36px rgba(0,0,0,.2);
    }

    p {
        margin-left: 1rem;
        color: #fff;
        font-weight: 700;

        a {
            display: block;
            font-size: .9rem;
            margin-top: .2rem;
            color: #fff;
            font-weight: 500;
            opacity: .5;
        }
    }
}