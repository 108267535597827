.form__number {
    position: relative;

    input {
        border: var(--main-color-border) solid 1px;
        padding: 1rem 1.25rem;
        font: var(--main-font-size) var(--main-font-family);
        width: 100%;
        border-radius: .2rem;
        background-color: var(--main-color-input-background);
        transition: .2s all;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    
        &::placeholder {
            color: var(--main-color-input-placeholder);
            opacity: 1;
        }
    
        &:focus {
            transition: .2s all;
            outline: 0 none;
            border-color: --main-color-border;
            box-shadow: 0 0 0 2px var(--main-color-secondary);
        }        
    }

    &__control {
        position: absolute;
        right: 0;
        width: 2rem;
        display: flex;
        flex-direction: column;
        top: 0;
    }

    &__plus,
    &__minus {
        border: solid 1px var(--main-color-border);
        background-color: #fff;
        height: 1.875rem;
        line-height: 1.9rem;
        width: 100%;;
        text-align: center;
        color: var(--main-color-primary);

        &:hover {
            background-color: var(--main-color-secondary);
            cursor: pointer;
        }
    }

    &__plus {
        border-radius: 0 .2rem 0 0;
    }

    &__minus {
        margin-top: -1px;
        border-radius: 0 0 .2rem 0;
    }


    &--has-error {
        border-color: var(--main-color-error);
        box-shadow: 0 0 0 2px var(--main-color-error);
    }
}
