.create {
    width: 100%;
    height: 100vh;
    background-color: var(--main-color-primary);
    position: relative;

    &__container {
        height: 100vh;
        width: 60vw;
        background-color: rgba(0,0,0,.1);
        right: 0;
        position: fixed;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        align-items: center;
    }

    &__content {
        width: 460px;
        padding: 5rem 0;
        margin: auto;
    }
}