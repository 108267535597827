.Tratto {
    h2 {
        color: var(--main-color-primary);
        font-size: 2rem;
    }

    &__columns,
    &__buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1.5rem;
        margin-bottom: 2rem;
    }

    &__label {
        color: #C2C8EB;
        text-transform: uppercase;
        font-size: .8rem;
        letter-spacing: .1rem;
    }

    &__text {
        font-size: 1.4rem;
        line-height: 2rem;
        margin: 0 0 3rem 0;
        padding: 0;
        color: var(--main-color-primary);
    }

    &__qrcode {
        text-align: center;
        margin-bottom: 3rem;

        img {
            max-width: 260px;
        }
    }

    h3 {
        font-size: 1.5rem;
        text-align: center;
        color: var(--main-color-primary);
    }

    &__download {
        display: flex;
        justify-content: center;
        
        a {
            width: 120px;
            height: 40px;  
            display: block;  
            background-size: cover;    
            text-indent: -99999px;    
        }

        .Tratto__apple {
            background-image: url('download-apple.webp');
            margin-right: 1rem;
        }

        .Tratto__google {
            background-image: url('download-google.webp');
        }
    }

}