// Get font
@import-normalize;
@import url('https://fonts.googleapis.com/css?family=Poppins:500,700&display=swap');

  // Variables
  :root {
    --main-font-size: 1rem;
    --main-font-family: 'Poppins', 'Helvetica', arial, sans-serif;

    --main-color-primary: #334AC0;
    --main-color-primary-rgb: 51,74,192;
    --main-color-primary-darken: #2D42AC;
    
    --main-color-secondary: #35CA74;
    --main-color-secondary-rgb: 53,202,116;
    --main-color-secondary-darken: #299659;
    --main-color-secondary-lighten: #34D679;

    --main-color-error: #D9405C;
    --main-color-error-rgb: 271,64,92;

    --main-color-label: #7d8cdc;
    --main-color-border: #2e42ac;
    --main-color-input-background: #f6f7fc;
    --main-color-input-placeholder: #BEC5ED;
  }  

  // Default Styles for project 

  * {
    box-sizing: border-box;
  }

  html {
    font-size: 16px;
    padding: 0;
    background-color: var(--main-color-primary);
  }

  body {
    font-size: var(--main-font-size);
    font-family: var(--main-font-family);
    margin: 0;
    overflow: hidden;
  }

  a {
    color: #000;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  h2 {
    font-size: 2rem;
    line-height: 2.75rem;
  }