.form__row {
    margin-bottom: 1.5rem;
    position: relative;

    &--button {
        margin-top: 3rem;
    }

    .form__error {
        color: var(--main-color-error);
        font-size: .9rem;
        margin-top: .5rem;
        display: block;
    }

    &--number {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;

        .form__number {
            width: 120px;
        }

        label {
            font-size: 1.1rem;
            line-height: 1.5rem;
            margin-left: 1rem;
            text-transform: initial;
            margin-bottom: 0;
            color: #000;
        }
    }
}