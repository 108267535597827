.Modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: none;

    &--show {
      display: block;
      
      .Modal__overlay {
        animation-name: fadeIn;
      }

      .Modal__container {
        animation-name: bounceInRight;
      }
    }

    &--hide {
      display: block;

      .Modal__overlay {
        animation-name: fadeOut;
      }

      .Modal__container {
        animation-name: bounceOutRight;
      }
    }

    &__overlay {
        width: 100%;
        height: 100vh;
        background-color: rgba(var(--main-color-primary-rgb), .8);
        transition: all .2s;
        animation-duration: .5s;
        animation-fill-mode: both;
        z-index: 0;
        position: absolute;
    }

    &__container {
        width: 40vw;
        height: 100vh;
        background-color: #fff;
        right: 0;
        position: absolute;
        padding: 3rem;
        animation-duration: .5s;
        animation-fill-mode: both;
        z-index: 100;
        overflow: auto;
    }

    &__close {
      font-size: 2rem;
      cursor: pointer;
      color: var(--main-color-primary);
    }
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes bounceInRight {
    from,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  
    from {
      opacity: 0;
      transform: translate3d(3000px, 0, 0);
    }
  
    60% {
      opacity: 1;
      transform: translate3d(-25px, 0, 0);
    }
  
    75% {
      transform: translate3d(10px, 0, 0);
    }
  
    90% {
      transform: translate3d(-5px, 0, 0);
    }
  
    to {
      transform: translate3d(0, 0, 0);
    }
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}