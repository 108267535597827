.form__file {
    p {
        display: block;
        width: 100%;
        border: 2px dashed var(--main-color-input-placeholder);
        padding: 2.5rem;
        margin-bottom: 0;
        border-radius: .5rem;
        text-align: center;
        color: var(--main-color-label);
    }
}