.form__phone {
    position: relative;
    
    span {
        display: flex;
        background-color: #EAECF6;
        position: absolute;
        padding: .78rem 1rem;
        border-radius: .2rem;
        top: .45rem;
        left: .45rem;
        align-items: center;
        font-size: 1rem;
        color: var(--main-color-primary);

        img {
            margin-right: .75rem;
        }
    }

    input {
        border: var(--main-color-border) solid 1px;
        padding: 1rem 1.25rem 1rem 8rem;
        font: var(--main-font-size) var(--main-font-family);
        width: 100%;
        border-radius: .2rem;
        background-color: var(--main-color-input-background);
        transition: .2s all;

        &::placeholder {
            color: var(--main-color-input-placeholder);
            opacity: 1;
        }
    
        &:focus {
            transition: .2s all;
            outline: 0 none;
            border-color: --main-color-border;
            box-shadow: 0 0 0 2px var(--main-color-secondary);
        }
    }


    &--has-error {
        border-color: var(--main-color-error);
        box-shadow: 0 0 0 2px var(--main-color-error);
    }
}
