.AfterPayment {
    h3 {
        font-size: 2rem;
        color: var(--main-color-primary);
    }

    .blockInput {
        border: #e1e1e1 solid 1px;
        padding: 1.5rem;
        border-radius: .2rem;
        margin-bottom: 1rem;

        &__choice {
            display: flex;
            align-items: center;

            &.is-active {
                background-color: red;
            }

            label {
                font-size: 1.2rem;
                line-height: 1.6rem;
                margin-left: 1.5rem;
                width: calc(100% - 50px);
            }

            input[type="radio"] {
                appearance: none;
                width: 25px;
                height: 25px;
                display: block;
                background-color: #fff;
                border: 3px solid var(--main-color-primary);
                box-shadow: inset 0 0 0 3px #fff;
                border-radius: 1.5rem;
                cursor: pointer;

                &:checked {
                    background-color: var(--main-color-primary);
                }

                &:focus {
                    outline: 0 none;
                }
            }
        }

        &__InnerInput {
            margin-top: 2rem;
            display: none;

            &.is-active {
                display: block;
            }

            .form__row {
                margin-bottom: 0;
            }
        }
    }
}