.CreatedList {

    h3 {
        font-size: 3rem;
        color: #fff;
    }

    table {
        width: 100%;
        margin: 0;
        padding: 0;
        background-color: #fff;
        border-radius: .3rem;
        box-shadow: 0 1px 2px rgba(0,0,0,.2);
        border-spacing: 0;
    
        thead {
            tr {
    
                th {
                    color: rgba(var(--main-color-primary-rgb), .5);
                    font-size: .6rem; 
                    text-transform: uppercase;
                    letter-spacing: .2rem;
                    padding: 1.5rem;
                    background-color: rgba(var(--main-color-primary-rgb), .1);
                 }
            }
        }
    
        tbody {
            tr {
                td {
                    font-size: .9rem;
                    text-align: center;
                    padding: 1.5rem .75rem 1.5rem 1.5rem; 
                    text-transform: capitalize;
                    border-bottom: 1px solid rgba(var(--main-color-primary-rgb), .1); 
    
                    &.date {
                        color: #999;
                    }
    
                    .tag {
                        padding: .5rem 1rem;
                        border-radius: 2rem;
    
                        &.wait {
                            background-color: rgba(170,190,219,.1);
                            color: rgba(170,190,219,1);
                        }
    
                        &.payed {
                            background-color: rgba(var(--main-color-secondary-rgb),.1);
                            color: var(--main-color-secondary);
                        }
    
                        &.expired {
                            background-color: rgba(var(--main-color-error-rgb),.1);
                            color: var(--main-color-error);
                        }
                    }
    
                    .btn {
                        background-color: var(--main-color-primary);
                        color: #fff;
                        border: none;
                        padding: .5rem 1rem;
    
                        &:hover {
                            background-color: var(--main-color-primary-darken);
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}
