.Receiver {
    width: 50vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    &__content {
        max-width: 500px;
        padding: 1rem;
        display: flex;
    }

    &__image {
        img {
            width: 70px;
            height: 70px;
            border-radius: 70px;
            box-shadow: 0 1px 16px rgba(0,0,0,.4);
        }
    }

    &__info {
        h2 {
            margin: 0;
            padding: 0;
            font-weight: normal;
            
            span {
                font-weight: 800;
            }
        }

         p {
             font-size: 1.4rem;
             line-height: 2.2rem;

             a {
                 text-decoration: underline;
                 font-weight: 800;

                 &:hover {
                     color: var(--main-color-primary);
                 }
             }
         }
    }
}