.form__control {
    border: var(--main-color-border) solid 1px;
    padding: 1rem 1.25rem;
    font: var(--main-font-size) var(--main-font-family);
    width: 100%;
    border-radius: .2rem;
    background-color: var(--main-color-input-background);
    transition: .2s all;

    &::placeholder {
        color: var(--main-color-input-placeholder);
        opacity: 1;
    }

    &:focus {
        transition: .2s all;
        outline: 0 none;
        border-color: --main-color-border;
        box-shadow: 0 0 0 2px var(--main-color-secondary);
    }

    &--has-error {
        border-color: --main-color-error;
        box-shadow: 0 0 0 2px var(--main-color-error);
    }
}