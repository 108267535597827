
.Navigation {
    ul {
        width: 320px;
        position: fixed;
        top: 50%;
        left: calc(40vw - 260px);
        transform: translateY(-50%);
        list-style-type: none;
        margin: 0;
        padding: 0;
        z-index: 10;

        li {
            margin-top: .5rem;

            a {
                padding: 1.7rem 1.75rem;
                display: flex;
                align-items: center;
                background-color: rgba(255,255,255,.1);
                width: 240px;
                color: #fff;
                border-radius: .2rem;
                opacity: .5;

                &:hover {
                    text-decoration: none;
                    opacity: 1;
                }

                &.active {
                    background-image: url("./background.active-menu.svg");
                    background-repeat: no-repeat;
                    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, .1)) drop-shadow(16px 16px 36px rgba(0, 0, 0, .3));    
                    opacity: 1;
                    position: relative;
                    border-radius: .2rem 0 0 .2rem;
                    width: 320px;
                    background-color: transparent;
                }

                .icon {
                    width: 30px;
                    height: 28px;
                    display: block;
                    margin-right: 1.25rem;
                    background-repeat: no-repeat;
                    background-position: center center;

                    &--create-link {
                        background-image: url("./icon.create-link.svg");
                    }

                    &--send-email {
                        background-image: url("./icon.send-email.svg");
                    }

                    &--send-sms {
                        background-image: url("./icon.send-sms.svg");
                    }

                    &--create-qrcode {
                        background-image: url("./icon.create-qrcode.svg");
                    }
                }
            }
        }
    }
}