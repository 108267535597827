.Pay {
    width: 50vw;
    height: 100vh;
    background-color: var(--main-color-primary);
    overflow: auto;
    padding: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &__container {
        max-width: 460px;
    }

    &__label {
        font-size: .8rem;
        text-transform: uppercase;
        color: rgba(255,255,255,.5);
        letter-spacing: .1rem;
        margin-bottom: .4rem;
    }

    &__value {
        font-size: 4rem;
        margin: 0 0 3rem 0;
        color: #fff;
    }

    &__text {
        color: #fff;
        font-size: 1.2rem;
        margin-bottom: 2rem;
        line-height: 2.2rem;

        p {
            margin: 0;
        }
    }

    &__columns {
        display: flex;
        margin-top: 1rem;

        span {
            display: block;
            font-size: .8rem;
            color: #fff;
            width: 110px;
            text-align: center;
            line-height: 1.3rem;
            border: #fff solid 1px;
            padding: 4rem 1rem 1rem 1rem;
            margin-right: 1rem;
            border-radius: .3rem;
            background-repeat: no-repeat;
            background-position: center 1.5rem;
            cursor: pointer;

            &:hover {
                text-decoration: none;
                background-color: rgba(255,255,255,.1);
            }
        }

        .Pay__credit-card {
            background-image: url('ico.credit-card.svg');
        }

        .Pay__boleto {
            background-image: url('ico.boleto.svg');
        }

        .Pay__tratto {
            background-image: url('ico.tratto.svg');
        }
    }
}