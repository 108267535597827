
.main-navigation {
    position: fixed;
    left: 2.5rem;
    top: 50%;
    transform: translateY(-50%);

    a {
        display: flex;
        margin-bottom: .5rem;
        align-items: center;
        position: relative;
        color: #fff;

        &:before {
            content: ".";
            display: block;
            width: 3rem;
            height: 3rem;
            background-color: rgba(255,255,255,.2);
            background-repeat: no-repeat;
            background-position: center center;
            border-radius: .2rem;
            text-indent: -99999px;
        }

        &.active:before {
            box-shadow: 0 1px 2px rgba(0,0,0,.1);
        }

        &.main-navigation__create:before {
            background-image: url('./icon.create.svg');
        }

        &.main-navigation__create.active:before {
            background-image: url('./icon.create.svg'), linear-gradient(#fff, #9DA9E8);
        }
    
        &.main-navigation__created:before {
            background-image: url('./icon.created.svg');
        }

        &.main-navigation__created.active:before {
            background-image: url('./icon.created.svg'), linear-gradient(#fff, #9DA9E8);
        }
    
        &.main-navigation__settings:before {
            background-image: url('./icon.settings.svg');
        }

        span {
            display: none;
            margin-left: .5rem;
            background-color: var(--main-color-primary-darken);
            padding: .5rem;
            border-radius: .3rem;
        }

        &:hover {
            text-decoration: none;

            &:before {
                background-color: #fff;
            }

            span {
                display: block;
            }
        }
    }
}