.Logo {
    position: fixed;
    top: 2.5rem;
    left: 2.5rem;

    a {
        display: block;
        width: 167px;
        height: 32px;
        text-indent: -999999px;
    } 

    &--black {
        background-image: url('./Logo-Black.svg');
    }

    &--white {
        background-image: url('./Logo-White.svg');
    }
}