.form__money {
    display: flex;
    margin-top: 1rem;

    span {
        font-size: 1.5rem;
        font-weight: 700;
        margin-right: 1rem;
        color: var(--main-color-input-placeholder);
    }

    .form__control-money {
        font: 5rem var(--main-font-family);
        width: 100%;
        border: none;
        background-color: transparent;
        padding: 0;
        margin: -1rem 0 0 0;
        color: #fff;

        &::placeholder {
            color: var(--main-color-input-placeholder);
            opacity: 1;
        }

        &:focus {
            outline: 0 none;
        }

        &--has-error {
            &::placeholder {
                color: var(--main-color-error);
            }
        }
    }
}