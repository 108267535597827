.Login {
    width: 100%;
    height: 100vh;
    background-image: url('./login-bg.jpg');
    background-size: cover;

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
    }

    &__content {
        width: 400px;

        h2 { text-align: center; }
    }

    &__toTratto {
        border-top: 1px solid #e1e1e1;
        padding-top: .5rem;
        margin-top: 2rem;
        font-size: 1.25rem;

        a {
            display: block;
            margin-top: .5rem;
            font-weight: 700;
        }
    }
}