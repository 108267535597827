.btn {
    font: var(--main-font-size) var(--main-font-family);
    padding: 1rem 1.25rem;
    border-radius: .2rem;
    background-color: #e1e1e1;
    border: #e1e1e1 solid 1px;
    cursor: pointer;

    &:focus {
        outline: 0 none;
    }

    &:hover {
        text-decoration: none;
    }

    &--primary {
        border-color: var(--main-color-primary);
        background-color: var(--main-color-primary);
        color: #fff;

        &:hover {
            background-color: var(--main-color-primary-darken);
        }
    }

    &--secondary {
        border: none;
        border-bottom: 3px solid var(--main-color-secondary-darken);
        background-color: var(--main-color-secondary);
        color: #fff;
        border-radius: .2rem .2rem .3rem .3rem;
        box-shadow: inset 0 2px 0 rgba(255,255,255,.3), 
        inset 0 -1px 0 rgba(255,255,255,.2), 
        0 1px 2px rgba(0,0,0,.2),
        0 0 20px rgba(0,0,0,.2);

        &:hover {
            background-color: var(--main-color-secondary-lighten);
        }
    }

    &--block {
        display: block;
        width: 100%;
        text-align: center;
    }

    &--lg {
        font-size: 1.4rem;
        font-weight: 800;
        padding: 1.2rem 2.2rem 1.1rem 2.2rem;
    }
}