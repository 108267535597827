.form__link {
    color: #fff;
    font-size: 1.2rem;
    background: url('./Ico.svg') right .45rem no-repeat;
    padding: 0 .8rem 0 0;
    cursor: pointer;
    border-bottom: rgba(0,0,0,0) 2px solid;
    transition: all .2s;

    &:hover {
        padding-bottom: .2rem;
        border-bottom-color: var(--main-color-secondary);
    }
}
